<template>
  <div class="page">
    <div class="keywords bgcolor-m">
      <van-row>
        <van-col span="3" @click="back()"
          ><div class="back-btn">
            <van-icon name="arrow-left" />返回
          </div></van-col
        >
        <van-col span="21">
          <van-search
            v-model="keyword"
            :background="COLOR_M"
            placeholder="请输入搜索关键词"
            ref="keyword"
            @focus="search"
          ></van-search>
        </van-col>
      </van-row>
    </div>
    <van-dropdown-menu class="dropdown">
      <van-dropdown-item
        ref="classify"
        v-model="classifyCode"
        @change="classifyChose"
        :options="classifyOptions"
      ></van-dropdown-item>
    </van-dropdown-menu>
    <van-list
      :finished="finished"
      finished-text="没有更多了"
      offset="0"
      class="task-list"
      :immediate-check="false"
      @load="retrieveTaskList"
    >
      <template #default>
        <van-row v-for="task in taskList" :key="task.code">
          <van-col @click="detail(task.code)" span="24">
            <van-row class="task">
              <van-col span="8">
                <div class="image">
                  <van-image radius="3" :src="task.imagePath"></van-image></div
              ></van-col>
              <van-col span="16">
                <van-row>
                  <van-col span="24" class="name">{{ task.name }}</van-col>
                </van-row>
                <van-row>
                  <van-col span="12" class="count color-s1"
                    >数量：{{ task.count }}人</van-col
                  >
                  <van-col span="12" class="classify"
                    >类型：<van-tag
                      :color="COLOR_M"
                      v-if="task.schoolType === 'GOV'"
                      >公办</van-tag
                    ><van-tag :color="COLOR_S1" v-if="task.schoolType === 'CLV'"
                      >民办</van-tag
                    ><van-tag :color="COLOR_S2" v-if="task.schoolType === 'ITA'"
                      >国际</van-tag
                    ></van-col
                  >
                </van-row>
                <van-row>
                  <van-col span="24" class="time"
                    >时间：{{ task.startTime }} 到 {{ task.endTime }}</van-col
                  >
                </van-row>
              </van-col>
            </van-row>
          </van-col>
        </van-row>
        <van-row v-if="taskList.length <= 0">
          <van-col span="24" class="no-record">
            <van-image
              width="103"
              height="103"
              :src="require('../../../../assets/images/home/no-record.png')"
            ></van-image>
          </van-col>
        </van-row>
      </template>
    </van-list>
    <Loading :show="loadingShow" />
    <Share ref="share" />
  </div>
</template>
<script>
import { Search, DropdownMenu, DropdownItem, Image, Tag, NavBar, Popup, List, Icon } from 'vant'
import Loading from '../../common/Loading.vue'
import Share from '../../common/Share.vue'
export default {
  components: {
    Loading: Loading,
    Share: Share,
    [Search.name]: Search,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Image.name]: Image,
    [Tag.name]: Tag,
    [NavBar.name]: NavBar,
    [Popup.name]: Popup,
    [List.name]: List,
    [Icon.name]: Icon
  },
  data () {
    return {
      finished: false,
      loadingShow: false,
      keyword: '',
      classifyOptions: [{ text: '学校分类', value: '' }],
      classifyCode: '',
      page: { current: 0, size: 10, last: 0 },
      taskList: []
    }
  },
  mounted () {
    document.title = '学校搜索'
    var query = this.$route.query
    if (query.keyword !== undefined && query.keyword !== null) {
      this.keyword = query.keyword
    }
    if (query.classifyCode !== undefined && query.classifyCode !== null) {
      this.classifyCode = query.classifyCode
    }
    this.retrieveClassifyOptions()
    this.retrieveTaskList()
    this.$refs.share.default('main')
  },
  methods: {
    search () {
      this.$router.push({ path: '/search/home', query: { keyword: this.keyword, type: 'ERO' } })
    },
    classifyChose () {
      this.taskList = []
      this.page.current = 0
      this.finished = false
      this.retrieveTaskList()
    },
    detail (code) {
      window.sessionStorage.setItem(this.SESSION_BACKURL, window.location.href)
      this.$router.push({ path: '/enroll/detail', query: { taskCode: code } })
    },
    async retrieveClassifyOptions () {
      var pd = { upperCode: '0' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/enroll/classify/retrieveClassifyOptionTree', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.classifyOptions = this.classifyOptions.concat(res.data)
      }
    },
    async retrieveTaskList () {
      if (!this.finished) {
        this.loadingShow = true
        this.page.current = this.page.current + 1
        var pd = { keyword: this.keyword, classifyCode: this.classifyCode, current: this.page.current, size: this.page.size }
        const { data: res } = await this.$http.post(this.BMS_URL + '/enroll/taskAPC/retrieveTaskList', this.$qs.stringify(pd))
        if (res.status === '200') {
          this.taskList = this.taskList.concat(res.data)
          this.page.last = res.page.last
          if (res.data.length <= 0) {
            this.finished = true
          }
        }
        this.loadingShow = false
      }
    },
    back () {
      var role = window.sessionStorage.getItem(this.SESSION_ROLE)
      if (role === 'SEL') {
        this.$router.push({ path: '/seller/home/enroll' })
      } else {
        this.$router.push({ path: '/enroll/home' })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.keywords {
  .back-btn {
    font-size: 14px;
    font-weight: 600;
    height: 50px;
    line-height: 50px;
    color: #fff;
  }
  .text {
    color: #fff;
  }
}
.dropdown {
  ::v-deep .van-dropdown-menu__bar {
    height: 35px;
  }
}
.task-list {
  padding-bottom: 60px;
  width: 100%;
  .task {
    text-align: left;
    margin: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #eee;
    .name {
      padding-left: 10px;
      font-weight: 600;
      height: 25px;
      line-height: 25px;
      text-align: left;
      font-size: 14px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
    .count {
      padding-left: 10px;
      font-size: 12px;
    }
    .classify {
      text-align: center;
      height: 22px;
      line-height: 22px;
      font-size: 12px;
    }
    .time {
      padding-left: 10px;
      font-size: 12px;
      height: 22px;
      line-height: 22px;
      color: #8a8a8a;
      text-align: left;
    }
    ::v-deep .van-tag {
      font-size: 10px;
    }
  }
}
.no-record {
  margin-top: 80px;
}
</style>
